export interface ExternalUser {
    readonly id: string
    readonly firstName: string | null
    readonly lastName: string | null
    readonly login: string | null
    readonly email: string | null
    readonly phone: string | null
}

export const getExternalUserDisplayName = (user: ExternalUser): string => {
    const values: string[] = []

    if (user.lastName) {
        values.push(user.lastName)
    }

    if (user.firstName) {
        values.push(user.firstName)
    }

    const extra: string[] = []
    if (user.login) {
        extra.push(`login: ${user.login}`)
    }

    if (user.email) {
        extra.push(`email: ${user.email}`)
    }

    if (user.phone) {
        extra.push(`tel: ${user.phone}`)
    }

    if (extra.length > 0) {
        values.push(`(${extra.join(', ')})`)
    }

    return values.join(' ').trim()
}
