import { UserRole } from '../response'

export class UserEditRequest {
    constructor(
        readonly branchId: number,
        readonly jobPositionId: number,
        readonly firstName: string,
        readonly lastName: string,
        readonly phoneNumber: string,
        readonly rate: string,
        readonly branchRange: number | null,
        readonly useSmsNotifications: boolean,
        readonly externalId: string | null,
        readonly roles: UserRole[],
        readonly hiredAt: Date,
        readonly firedAt: Date | null
    ) {}
}
