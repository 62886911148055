import React, { useCallback } from 'react'
import { TableCell, TableRow, Tooltip, IconButton, Typography } from '@mui/material'
import ResetPhoneIcon from '@mui/icons-material/PhonelinkEraseOutlined'
import ShowMapIcon from '@mui/icons-material/MapOutlined'
import BranchIcon from '@mui/icons-material/BusinessOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/EditOutlined'
import LockIcon from '@mui/icons-material/LockOutlined'
import LockOpenIcon from '@mui/icons-material/LockOpenOutlined'
import KeyIcon from '@mui/icons-material/KeyOutlined'
import { User, UserStatus } from '../../api/response'
import { formatDate, formatOptionalDate } from '../../utils/formatDate'

const strings = {
    status: {
        active: 'Aktywny',
        locked: 'Zablokowany',
    },
    button: {
        delete: 'Usuń',
        edit: 'Edytuj',
        lock: 'Zablokuj',
        unlock: 'Odblokuj',
        password: 'Zmien hasło',
        showMap: 'Pokaż mapę',
        additionalBranches: 'Oddziały dodatkowe',
        resetPhone: 'Zezwól na nowy telefon',
    },
    label: {
        currently: 'obecnie',
    },
}

interface OwnProps {
    readonly user: User
    readonly hasShowUserMapRole: boolean
    readonly hasResetUserPhoneRole: boolean
    readonly hasManageAdditionalBranches: boolean
    readonly editUser: (user: User) => void
    readonly changePassword: (user: User) => void
    readonly deleteUser: (user: User) => void
    readonly changeStatus: (user: User, status: UserStatus) => void
    readonly showMap: (user: User) => void
    readonly resetPhone: (user: User) => void
    readonly manageAdditionalBranches: (user: User) => void
}

const UserTableRow: React.FunctionComponent<OwnProps> = ({
    user,
    hasShowUserMapRole,
    hasResetUserPhoneRole,
    hasManageAdditionalBranches,
    editUser,
    changePassword,
    deleteUser,
    changeStatus,
    showMap,
    resetPhone,
    manageAdditionalBranches,
}) => {
    const handleShowMap = useCallback(() => {
        showMap(user)
    }, [user])

    const handleAdditionalBranches = useCallback(() => {
        manageAdditionalBranches(user)
    }, [user])

    const handleResetPhone = useCallback(() => {
        resetPhone(user)
    }, [user])

    const handleEdit = useCallback(() => {
        editUser(user)
    }, [user])

    const handleDelete = useCallback(() => {
        deleteUser(user)
    }, [user])

    const handleChangeStatus = useCallback(() => {
        if (user.status === UserStatus.Active) {
            changeStatus(user, UserStatus.Locked)
        } else if (user.status === UserStatus.Locked) {
            changeStatus(user, UserStatus.Active)
        }
    }, [user])

    const handleChangePassword = useCallback(() => {
        changePassword(user)
    }, [user])

    return (
        <TableRow hover={true}>
            <TableCell>
                {user.id} {!!user.externalId && `[${user.externalId}]`}
            </TableCell>
            <TableCell>
                <Typography variant="inherit">{user.email}</Typography>
                <Typography variant="inherit">
                    {user.firstName} {user.lastName}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="inherit">{user.branch.name}</Typography>
                <Typography variant="inherit">{user.jobPosition.name}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="inherit">
                    {user.status === UserStatus.Active && strings.status.active}
                    {user.status === UserStatus.Locked && strings.status.locked}
                </Typography>
                <Typography variant="inherit">
                    {formatDate(user.hiredAt)}&nbsp;—&nbsp;
                    {formatOptionalDate(user.firedAt) ?? strings.label.currently}
                </Typography>
            </TableCell>
            <TableCell>{user.forbiddenPlaces}</TableCell>
            <TableCell>
                {hasResetUserPhoneRole && (
                    <Tooltip title={strings.button.resetPhone}>
                        <IconButton onClick={handleResetPhone}>
                            <ResetPhoneIcon />
                        </IconButton>
                    </Tooltip>
                )}

                {hasShowUserMapRole && (
                    <Tooltip title={strings.button.showMap}>
                        <IconButton onClick={handleShowMap}>
                            <ShowMapIcon />
                        </IconButton>
                    </Tooltip>
                )}

                {hasManageAdditionalBranches && (
                    <Tooltip title={strings.button.additionalBranches}>
                        <IconButton onClick={handleAdditionalBranches}>
                            <BranchIcon />
                        </IconButton>
                    </Tooltip>
                )}
                <br />
                {user.status === UserStatus.Active && (
                    <Tooltip title={strings.button.lock}>
                        <IconButton onClick={handleChangeStatus}>
                            <LockIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {user.status === UserStatus.Locked && (
                    <Tooltip title={strings.button.unlock}>
                        <IconButton onClick={handleChangeStatus}>
                            <LockOpenIcon />
                        </IconButton>
                    </Tooltip>
                )}
                <Tooltip title={strings.button.edit}>
                    <IconButton onClick={handleEdit}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={strings.button.password}>
                    <IconButton onClick={handleChangePassword}>
                        <KeyIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={strings.button.delete}>
                    <IconButton onClick={handleDelete}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}

export default React.memo(UserTableRow)
