export enum MenuItemType {
    Branches = 'Oddziały',
    Statuses = 'Statusy',
    Holidays = 'Święta',
    JobPositions = 'Stanowiska pracy',
    Schedule = 'Harmonogram',
    Tasks = 'Zadania',
    Users = 'Użytkownicy',
    Map = 'Mapa',
    ForbiddenPlaces = 'Miejsca zabronione',
}
