import * as Model from './response'
import * as Request from './request'
import client from './client'

interface PaginationParams {
    limit: undefined | number
    offset: undefined | number
}

//#region Auth
const authToken = async (username: string, password: string): Promise<Model.AccessToken> => {
    const data = {
        password,
        username,
    }
    const response = await client.post<Model.AccessToken>('oauth2/token', data)

    return response.data
}

const authAccount = async (token: string): Promise<Model.Account> => {
    const response = await client.get<Model.Account>(`me`, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    })

    return response.data
}
// #endregion

//#region Account
const accountMe = async (): Promise<Model.Account> => {
    const response = await client.get<Model.Account>(`me`)

    return response.data
}
//#endregion

//#region Branch
const branchesList = async (limit: number | null = null, offset: number | null = null): Promise<Model.Branch[]> => {
    const params: PaginationParams = {
        limit: undefined,
        offset: undefined,
    }

    if (limit !== null) {
        params.limit = limit
    }

    if (offset !== null) {
        params.offset = offset
    }

    const response = await client.get<Model.Branch[]>('branches', { params })

    return response.data
}

const branchesAll = async (limit: number | null = null, offset: number | null = null): Promise<Model.Branch[]> => {
    const params: PaginationParams = {
        limit: undefined,
        offset: undefined,
    }

    if (limit !== null) {
        params.limit = limit
    }

    if (offset !== null) {
        params.offset = offset
    }

    const response = await client.get<Model.Branch[]>('branches/all', { params })

    return response.data
}

const branchesDetails = async (branchId: number): Promise<Model.Branch> => {
    const response = await client.get<Model.Branch>(`branches/${branchId}`)

    return response.data
}

const branchesAdd = async (data: Request.BranchRequest): Promise<Model.Branch> => {
    const response = await client.post<Model.Branch>('branches', data)

    return response.data
}

const branchesEdit = async (branchId: number, data: Request.BranchRequest): Promise<Model.Branch> => {
    const response = await client.put<Model.Branch>(`branches/${branchId}`, data)

    return response.data
}

const branchesDelete = async (branchId: number): Promise<void> => {
    const response = await client.delete<void>(`branches/${branchId}`)

    return response.data
}

const branchesRestore = async (branchId: number): Promise<Model.Branch> => {
    const response = await client.post<Model.Branch>(`branches/${branchId}/restore`)

    return response.data
}
//#endregion

//#region Status
const statusesList = async (limit: number | null = null, offset: number | null = null): Promise<Model.Status[]> => {
    const params: PaginationParams = {
        limit: undefined,
        offset: undefined,
    }

    if (limit !== null) {
        params.limit = limit
    }

    if (offset !== null) {
        params.offset = offset
    }

    const response = await client.get<Model.Status[]>('statuses', { params })

    return response.data
}

const statusesDetails = async (statusId: number): Promise<Model.Status> => {
    const response = await client.get<Model.Status>(`statuses/${statusId}`)

    return response.data
}

const statusesAdd = async (data: Request.StatusRequest): Promise<Model.Status> => {
    const response = await client.post<Model.Status>('statuses', data)

    return response.data
}

const statusesEdit = async (statusId: number, data: Request.StatusRequest): Promise<Model.Status> => {
    const response = await client.put<Model.Status>(`statuses/${statusId}`, data)

    return response.data
}

const statusesDelete = async (statusId: number): Promise<void> => {
    const response = await client.delete<void>(`statuses/${statusId}`)

    return response.data
}
//#endregion

//#region Holiday
const holidaysList = async (limit: number | null = null, offset: number | null = null): Promise<Model.Holiday[]> => {
    const params: PaginationParams = {
        limit: undefined,
        offset: undefined,
    }

    if (limit !== null) {
        params.limit = limit
    }

    if (offset !== null) {
        params.offset = offset
    }

    const response = await client.get<Model.Holiday[]>('holidays', { params })

    return response.data
}

const holidaysDetails = async (holidayId: number): Promise<Model.Holiday> => {
    const response = await client.get<Model.Holiday>(`holidays/${holidayId}`)

    return response.data
}

const holidaysAdd = async (data: Request.HolidayRequest): Promise<Model.Holiday> => {
    const response = await client.post<Model.Holiday>('holidays', data)

    return response.data
}

const holidaysEdit = async (holidayId: number, data: Request.HolidayRequest): Promise<Model.Holiday> => {
    const response = await client.put<Model.Holiday>(`holidays/${holidayId}`, data)

    return response.data
}

const holidaysDelete = async (holidayId: number): Promise<void> => {
    const response = await client.delete<void>(`holidays/${holidayId}`)

    return response.data
}
//#endregion

//#region ForbiddenPlace
const forbiddenPlacesList = async (limit: number | null = null, offset: number | null = null): Promise<Model.ForbiddenPlace[]> => {
    const params: PaginationParams = {
        limit: undefined,
        offset: undefined,
    }

    if (limit !== null) {
        params.limit = limit
    }

    if (offset !== null) {
        params.offset = offset
    }

    const response = await client.get<Model.ForbiddenPlace[]>('forbiddenplaces', { params })

    return response.data
}

const forbiddenPlacesDetails = async (forbiddenPlaceId: number): Promise<Model.ForbiddenPlace> => {
    const response = await client.get<Model.ForbiddenPlace>(`forbiddenplaces/${forbiddenPlaceId}`)

    return response.data
}

const forbiddenPlacesAdd = async (data: Request.ForbiddenPlaceRequest): Promise<Model.ForbiddenPlace> => {
    const response = await client.post<Model.ForbiddenPlace>('forbiddenplaces', data)

    return response.data
}

const forbiddenPlacesEdit = async (forbiddenPlaceId: number, data: Request.ForbiddenPlaceRequest): Promise<Model.ForbiddenPlace> => {
    const response = await client.put<Model.ForbiddenPlace>(`forbiddenplaces/${forbiddenPlaceId}`, data)

    return response.data
}

const forbiddenPlacesDelete = async (forbiddenPlaceId: number): Promise<void> => {
    const response = await client.delete<void>(`forbiddenplaces/${forbiddenPlaceId}`)

    return response.data
}
//#endregion

//#region JobPosition
const jobPositionsList = async (limit: number | null = null, offset: number | null = null): Promise<Model.JobPosition[]> => {
    const params: PaginationParams = {
        limit: undefined,
        offset: undefined,
    }

    if (limit !== null) {
        params.limit = limit
    }

    if (offset !== null) {
        params.offset = offset
    }

    const response = await client.get<Model.JobPosition[]>('jobpositions', { params })

    return response.data
}

const jobPositionsDetails = async (jobPositionId: number): Promise<Model.JobPosition> => {
    const response = await client.get<Model.JobPosition>(`jobpositions/${jobPositionId}`)

    return response.data
}

const jobPositionsAdd = async (data: Request.JobPositionRequest): Promise<Model.JobPosition> => {
    const response = await client.post<Model.JobPosition>('jobpositions', data)

    return response.data
}

const jobPositionsEdit = async (jobPositionId: number, data: Request.JobPositionRequest): Promise<Model.JobPosition> => {
    const response = await client.put<Model.JobPosition>(`jobpositions/${jobPositionId}`, data)

    return response.data
}

const jobPositionsDelete = async (jobPositionId: number): Promise<void> => {
    const response = await client.delete<void>(`jobpositions/${jobPositionId}`)

    return response.data
}
//#endregion

//#region Schedule
const scheduleBranches = async (): Promise<Model.Branch[]> => {
    const response = await client.get<Model.Branch[]>(`schedules/branches`)

    return response.data
}

const scheduleDetails = async (request: Request.ScheduleRequest): Promise<Model.Schedule> => {
    const response = await client.get<Model.Schedule>(`schedules`, {
        params: {
            ...request,
        },
    })

    return response.data
}

const scheduleClose = async (scheduleId: string): Promise<void> => {
    const response = await client.post<void>(`schedules/${scheduleId}/close`)

    return response.data
}

const scheduleShiftCreate = async (scheduleId: string, data: Request.ScheduleShiftRequest): Promise<Model.ScheduleUserDayShift> => {
    const response = await client.post<Model.ScheduleUserDayShift>(`schedules/${scheduleId}/shifts`, data)

    return response.data
}

const scheduleShiftUpdate = async (scheduleId: string, shiftId: number, data: Request.ScheduleShiftRequest): Promise<Model.ScheduleUserDayShift> => {
    const response = await client.put<Model.ScheduleUserDayShift>(`schedules/${scheduleId}/shifts/${shiftId}`, data)

    return response.data
}

const scheduleShiftHistory = async (scheduleId: string, shiftId: number): Promise<Model.ShiftHistory[]> => {
    const response = await client.get<Model.ShiftHistory[]>(`schedules/${scheduleId}/shifts/${shiftId}/history`)

    return response.data
}

const scheduleShiftDelete = async (scheduleId: string, shiftId: number): Promise<Model.ScheduleUserDayShift> => {
    const response = await client.post<Model.ScheduleUserDayShift>(`schedules/${scheduleId}/shifts/${shiftId}/delete`)

    return response.data
}

const scheduleShiftRestore = async (scheduleId: string, shiftId: number): Promise<Model.ScheduleUserDayShift> => {
    const response = await client.post<Model.ScheduleUserDayShift>(`schedules/${scheduleId}/shifts/${shiftId}/restore`)

    return response.data
}

const scheduleDayUpdate = async (scheduleId: string, data: Request.ScheduleDayRequest): Promise<void> => {
    const response = await client.post<void>(`schedules/${scheduleId}/days`, data)

    return response.data
}

const scheduleExportUser = async (scheduleId: string, userId: number): Promise<Blob> => {
    const response = await client.get(`schedules/${scheduleId}/users/${userId}`, { responseType: 'arraybuffer' })

    return new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
}

const scheduleExport = async (scheduleId: string): Promise<Blob> => {
    const response = await client.get(`schedules/${scheduleId}/export`, { responseType: 'arraybuffer' })

    return new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
}
//#endregion

//#region Shift
const shiftAdd = async (scheduleId: string, userId: number, data: Request.UserShiftRequest): Promise<Model.UserDayShift> => {
    const response = await client.post<Model.UserDayShift>(`/schedules/${scheduleId}/user-shifts/${userId}`, data)

    return response.data
}

const shiftEdit = async (uuid: string, data: Request.UserShiftRequest): Promise<Model.UserDayShift> => {
    const response = await client.post<Model.UserDayShift>(`shifts/${uuid}`, data)

    return response.data
}

const shiftSplit = async (uuid: string, data: Request.SplitShiftRequest): Promise<Model.SplitShifts> => {
    const response = await client.post<Model.SplitShifts>(`shifts/${uuid}/split`, data)

    return response.data
}

const shiftHistory = async (uuid: string): Promise<Model.ShiftHistory[]> => {
    const response = await client.get<Model.ShiftHistory[]>(`shifts/${uuid}/history`)

    return response.data
}

const shiftHide = async (uuid: string): Promise<Model.UserDayShift> => {
    const response = await client.post<Model.UserDayShift>(`shifts/${uuid}/hide`)

    return response.data
}

const shiftRestore = async (uuid: string): Promise<Model.UserDayShift> => {
    const response = await client.post<Model.UserDayShift>(`shifts/${uuid}/restore`)

    return response.data
}

const shiftEnd = async (uuid: string): Promise<Model.UserDayShift> => {
    const response = await client.post<Model.UserDayShift>(`shifts/${uuid}/end`)

    return response.data
}

const shiftApprove = async (uuid: string): Promise<Model.UserDayShift> => {
    const response = await client.post<Model.UserDayShift>(`shifts/${uuid}/approve`)

    return response.data
}

const shiftUndoApprove = async (uuid: string): Promise<Model.UserDayShift> => {
    const response = await client.delete<Model.UserDayShift>(`shifts/${uuid}/approve`)

    return response.data
}
//#endregion

//#region Tasks
const tasksBranches = async (): Promise<Model.Branch[]> => {
    const response = await client.get<Model.Branch[]>(`tasks/branches`)

    return response.data
}

const tasksStatuses = async (): Promise<Model.Status[]> => {
    const response = await client.get<Model.Status[]>(`tasks/statuses`)

    return response.data
}

const tasksUsers = async (branchId: number): Promise<Model.JobPositionUsers[]> => {
    const response = await client.get<Model.JobPositionUsers[]>(`tasks/branches/${branchId}/users`)

    return response.data
}

const tasksTasks = async (userId: number, limit: number | null = null, offset: number | null = null): Promise<Model.TaskDetails[]> => {
    const params: PaginationParams = {
        limit: undefined,
        offset: undefined,
    }

    if (limit !== null) {
        params.limit = limit
    }

    if (offset !== null) {
        params.offset = offset
    }

    const response = await client.get<Model.TaskDetails[]>(`tasks/users/${userId}`, { params })

    return response.data
}

const tasksDetails = async (taskId: number): Promise<Model.TaskDetails> => {
    const response = await client.get<Model.TaskDetails>(`tasks/${taskId}`)

    return response.data
}

const tasksAdd = async (userId: number, data: Request.TaskRequest): Promise<Model.TaskDetails> => {
    const response = await client.post<Model.TaskDetails>(`tasks/users/${userId}`, data)

    return response.data
}

const tasksEdit = async (taskId: number, data: Request.TaskRequest): Promise<Model.TaskDetails> => {
    const response = await client.put<Model.TaskDetails>(`tasks/${taskId}`, data)

    return response.data
}

const tasksDelete = async (taskId: number): Promise<void> => {
    const response = await client.delete<void>(`tasks/${taskId}`)

    return response.data
}
//#endregion

//#region Users
const usersBranches = async (): Promise<Model.Branch[]> => {
    const response = await client.get<Model.Branch[]>(`users/branches`)

    return response.data
}

const usersJobPositions = async (): Promise<Model.JobPosition[]> => {
    const response = await client.get<Model.JobPosition[]>(`users/job-positions`)

    return response.data
}

const usersUsers = async (
    branchId: number | null = null,
    noShiftDays: number | null = null,
    limit: number | null = null,
    offset: number | null = null,
    signal?: AbortSignal | undefined
): Promise<Model.User[]> => {
    interface Params {
        limit: undefined | number
        offset: undefined | number
        branchId: undefined | number
        noShiftDays: undefined | number
    }

    const params: Params = {
        limit: undefined,
        offset: undefined,
        branchId: undefined,
        noShiftDays: undefined,
    }

    if (limit !== null) {
        params.limit = limit
    }

    if (offset !== null) {
        params.offset = offset
    }

    if (branchId !== null) {
        params.branchId = branchId
    }

    if (noShiftDays !== null) {
        params.noShiftDays = noShiftDays
    }

    const response = await client.get<Model.User[]>(`users`, { signal, params: { ...params } })

    return response.data
}

const usersDetails = async (userId: number): Promise<Model.User> => {
    const response = await client.get<Model.User>(`users/${userId}`)

    return response.data
}

const usersAdd = async (data: Request.UserCreateRequest): Promise<Model.User> => {
    const response = await client.post<Model.User>('users', data)

    return response.data
}

const usersEdit = async (userId: number, data: Request.UserEditRequest): Promise<Model.User> => {
    const response = await client.put<Model.User>(`users/${userId}`, data)

    return response.data
}

const usersPassword = async (userId: number, data: Request.UserPasswordRequest): Promise<Model.User> => {
    const response = await client.post<Model.User>(`users/${userId}/password`, data)

    return response.data
}

const usersStatus = async (userId: number, status: Model.UserStatus): Promise<Model.User> => {
    const response = await client.put<Model.User>(`users/${userId}/status/${status}`)

    return response.data
}

const usersDelete = async (userId: number): Promise<void> => {
    const response = await client.delete<void>(`users/${userId}`)

    return response.data
}

const usersUserDay = async (userId: number, date: string | null = null): Promise<Model.UserDay> => {
    interface Params {
        date?: string
    }

    let params: Params = {}

    if (date !== null) {
        params = {
            date: date,
        }
    }

    const response = await client.get<Model.UserDay>(`users/${userId}/day`, { params })

    return response.data
}

const usersResetPhone = async (userId: number): Promise<void> => {
    const response = await client.post<void>(`users/${userId}/reset-phone`)

    return response.data
}

const usersRequestLocation = async (userId: number): Promise<void> => {
    const response = await client.post<void>(`users/${userId}/request-location`)

    return response.data
}

const externalUsersList = async (): Promise<Model.ExternalUser[]> => {
    const response = await client.get<Model.ExternalUser[]>('external/users')

    return response.data
}
//#endregion

//#region UserBranch
const userBranchList = async (userId: number): Promise<Model.UserBranch[]> => {
    const response = await client.get<Model.UserBranch[]>(`/user-branches/${userId}`)

    return response.data
}

const userBranchBranches = async (userId: number): Promise<Model.Branch[]> => {
    const response = await client.get<Model.Branch[]>(`/user-branches/${userId}/branches`)

    return response.data
}

const userBranchDetails = async (userId: number, itemId: number): Promise<Model.UserBranch> => {
    const response = await client.get<Model.UserBranch>(`/user-branches/${userId}/${itemId}`)

    return response.data
}

const userBranchAdd = async (userId: number, data: Request.UserBranchRequest): Promise<Model.UserBranch> => {
    const response = await client.post<Model.UserBranch>(`/user-branches/${userId}`, data)

    return response.data
}

const userBranchEdit = async (userId: number, itemId: number, data: Request.UserBranchRequest): Promise<Model.UserBranch> => {
    const response = await client.put<Model.UserBranch>(`/user-branches/${userId}/${itemId}`, data)

    return response.data
}

const userBranchDelete = async (userId: number, itemId: number): Promise<void> => {
    const response = await client.delete<void>(`/user-branches/${userId}/${itemId}`)

    return response.data
}
//#endregion

export const API = {
    auth: {
        token: authToken,
        account: authAccount,
    },
    account: {
        me: accountMe,
    },
    branches: {
        list: branchesList,
        all: branchesAll,
        details: branchesDetails,
        add: branchesAdd,
        edit: branchesEdit,
        delete: branchesDelete,
        restore: branchesRestore,
    },
    statuses: {
        list: statusesList,
        details: statusesDetails,
        add: statusesAdd,
        edit: statusesEdit,
        delete: statusesDelete,
    },
    holidays: {
        list: holidaysList,
        details: holidaysDetails,
        add: holidaysAdd,
        edit: holidaysEdit,
        delete: holidaysDelete,
    },
    forbiddenPlaces: {
        list: forbiddenPlacesList,
        details: forbiddenPlacesDetails,
        add: forbiddenPlacesAdd,
        edit: forbiddenPlacesEdit,
        delete: forbiddenPlacesDelete,
    },
    jobPositions: {
        list: jobPositionsList,
        details: jobPositionsDetails,
        add: jobPositionsAdd,
        edit: jobPositionsEdit,
        delete: jobPositionsDelete,
    },
    schedule: {
        branches: scheduleBranches,
        details: scheduleDetails,
        close: scheduleClose,
        shift: {
            create: scheduleShiftCreate,
            update: scheduleShiftUpdate,
            history: scheduleShiftHistory,
            delete: scheduleShiftDelete,
            restore: scheduleShiftRestore,
        },
        day: scheduleDayUpdate,
        exportUser: scheduleExportUser,
        export: scheduleExport,
    },
    shift: {
        add: shiftAdd,
        edit: shiftEdit,
        split: shiftSplit,
        history: shiftHistory,
        hide: shiftHide,
        restore: shiftRestore,
        end: shiftEnd,
        approve: shiftApprove,
        undoApprove: shiftUndoApprove,
    },
    tasks: {
        branches: tasksBranches,
        statuses: tasksStatuses,
        users: tasksUsers,
        tasks: tasksTasks,
        details: tasksDetails,
        add: tasksAdd,
        edit: tasksEdit,
        delete: tasksDelete,
    },
    users: {
        branches: usersBranches,
        jobPositions: usersJobPositions,
        users: usersUsers,
        details: usersDetails,
        add: usersAdd,
        edit: usersEdit,
        password: usersPassword,
        status: usersStatus,
        delete: usersDelete,
        external: externalUsersList,
        resetPhone: usersResetPhone,
        userDay: usersUserDay,
        requestLocation: usersRequestLocation,
    },
    userBranches: {
        list: userBranchList,
        branches: userBranchBranches,
        details: userBranchDetails,
        add: userBranchAdd,
        edit: userBranchEdit,
        delete: userBranchDelete,
    },
}
