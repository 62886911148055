import React, { useCallback, useEffect, useState } from 'react'
import { useStateContext } from '../../state/stateContext'
import {
    Box,
    CircularProgress,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Switch,
    TextField,
    Tooltip,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { Branch } from '../../api/response'
import { API } from '../../api/api'
import _, { toInteger } from 'lodash'
import Validator from 'validator'

const strings = {
    button: {
        create: 'Utwórz',
    },
    label: {
        all: 'Wszystkie',
        checkShifts: 'Sprawdź czas pracy',
        noShiftDays: 'Liczba dni bez pracy',
        branch: 'Oddział',
    },
}

interface OwnProps {
    readonly addUser: () => void
    readonly updateBranchId: (branchId: number | null) => void
    readonly updateNoShiftDays: (noShiftDays: number | null) => void
    readonly branchId: number | null
}

const UsersHeader: React.FunctionComponent<OwnProps> = ({ addUser, updateBranchId, updateNoShiftDays, branchId }) => {
    const { sessionState } = useStateContext()

    const [branches, setBranches] = useState<Branch[]>(() => [sessionState.account!.branch])
    const [checkShifts, setCheckShifts] = useState<boolean>(false)
    const [noShiftDays, setNoShiftDays] = useState<number>(7)
    const [loading, setLoading] = useState<boolean>(false)

    const onBranchChange = useCallback((event: SelectChangeEvent) => {
        const number = parseInt(event.target.value)

        if (number === -1) {
            updateBranchId(null)
        } else {
            updateBranchId(number)
        }
    }, [])

    const onSwitchChange = useCallback(
        (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
            setCheckShifts(checked)
            if (checked) {
                updateNoShiftDays(noShiftDays)
            } else {
                updateNoShiftDays(null)
            }
        },
        [noShiftDays]
    )

    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = event.target.value

        // eslint-disable-next-line camelcase
        if (Validator.isNumeric(value, { no_symbols: true })) {
            const intValue = toInteger(value)
            if (intValue > 0) {
                setNoShiftDays(intValue)
                updateNoShiftDays(intValue)
            }
        }
    }, [])

    useEffect(() => {
        setLoading(true)
        API.users
            .branches()
            .then((response) => {
                setBranches(response)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    return (
        <Stack
            direction="row"
            spacing={1}
            ml={3}
            mr={3}
        >
            {loading && (
                <Box>
                    <CircularProgress />
                </Box>
            )}

            <Box
                display="flex"
                justifyContent="center"
            >
                <Tooltip title={strings.button.create}>
                    <IconButton onClick={addUser}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            <Box sx={{ minWidth: 280 }}>
                <FormControl fullWidth={true}>
                    <InputLabel>{strings.label.branch}</InputLabel>
                    <Select
                        id="branch"
                        name="branch"
                        value={branchId?.toString() ?? '-1'}
                        label={strings.label.branch}
                        onChange={onBranchChange}
                    >
                        <MenuItem
                            key={0}
                            value={-1}
                        >
                            {strings.label.all}
                        </MenuItem>
                        {branches.map(
                            (value: Branch, index: number): React.ReactNode => (
                                <MenuItem
                                    key={index}
                                    value={value.id}
                                >
                                    {value.name}
                                </MenuItem>
                            )
                        )}
                    </Select>
                </FormControl>
            </Box>

            <Box
                display="flex"
                justifyContent="center"
            >
                <FormControlLabel
                    control={
                        <Switch
                            checked={checkShifts}
                            onChange={onSwitchChange}
                        />
                    }
                    label={strings.label.checkShifts}
                />
            </Box>

            {checkShifts && (
                <Box>
                    <TextField
                        value={noShiftDays}
                        multiline={false}
                        fullWidth={false}
                        label={strings.label.noShiftDays}
                        margin="none"
                        type="number"
                        onChange={onTextFieldChange}
                    />
                </Box>
            )}
        </Stack>
    )
}

export default React.memo(UsersHeader)
